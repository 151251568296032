import React, { Fragment, useCallback, useContext } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { TFunction, withTranslation } from "next-i18next";
import { AuthContext } from "@context";
import { CompanySocialMedia, Footer } from "#types/landing";
import { PAGES_COMPANY, SOCIAL_MEDIAL, TERMS_AND_CONDITIONS_SCHEMA, TPagesLinks } from "@schemas/footer";
import Description from "@components/landing/cardDescription/description.wrap";
import { CreateAccount } from "@components/signUp/createAccountButton/component";
import PoweredByImage from "@public/assets/images/magneto-official.svg";
import styles from "./footer.module.scss";
import { completeUrl, fillUrlUtms } from "@utils/url";
import classNames from "classnames";

type Props = {
  data: Footer;
  socialMedia: CompanySocialMedia;
  t: TFunction;
  icon?: string;
  isBrand?: boolean;
};

const FooterLanding: React.FC<Props> = ({ t, data, socialMedia, isBrand }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { termsAndConditions } = TERMS_AND_CONDITIONS_SCHEMA;
  const { footerText, socialIconsColor, footerFindVacantsText, footerBgColor, footerTextColor, footerIconUrl } = data;
  const { query } = useRouter();
  const { companySlug } = query;

  const urlCompanyPages = useCallback(
    ({ routePath, baseUrl }: TPagesLinks) => {
      const path = t<string>(routePath, { companySlug, webSite: socialMedia.webSite });
      return `${baseUrl}${path}`;
    },
    [t, companySlug, socialMedia.webSite]
  );

  return (
    <footer
      style={{
        background: footerBgColor ? footerBgColor : undefined,
        color: footerTextColor ? footerTextColor : undefined
      }}
      className={styles.footerLanding}
    >
      {footerIconUrl && (
        <div className={styles.nextImg}>
          <Image alt="logo" src={footerIconUrl} height={100} width={500} objectFit="contain" loading="lazy" />
        </div>
      )}
      {footerFindVacantsText && (
        <h4 style={footerTextColor ? { color: footerTextColor } : undefined}>{footerFindVacantsText}</h4>
      )}
      <div className={styles["footerLanding_marginText"]}>
        {SOCIAL_MEDIAL.map(({ props, tag }, key) => {
          if (tag === "webSite" && isBrand) return <Fragment key={`${key}_${tag}`} />;
          if (socialMedia[tag as keyof CompanySocialMedia]) {
            return (
              <a
                style={socialIconsColor ? { color: socialIconsColor } : undefined}
                className={classNames(styles["footerLanding_icons"], `${props.icon}`)}
                key={`${key}_${tag}`}
                href={fillUrlUtms(completeUrl(socialMedia[tag as keyof CompanySocialMedia]), query)}
                target="_blank"
                rel="noreferrer"
              />
            );
          }
        })}
      </div>

      <div className={`${styles["footerLanding_marginText"]} ${styles.links}`}>
        {PAGES_COMPANY.map((item, key) => {
          if (
            (item.tag.includes("officialSite") && isBrand) ||
            (item.tag.includes("registerResume") && isAuthenticated)
          )
            return <Fragment key={`${key}_${item.tag}`} />;

          if (item.tag.includes("registerResume"))
            return (
              <CreateAccount
                key={`${key}_${item.tag}`}
                t={t}
                name={t<string>(item.tag)}
                propStyle={styles.btnRegister}
                withIcon={false}
              />
            );

          const urlWithUtms = fillUrlUtms(urlCompanyPages(item), query);
          const anchor = (
            <a
              className={styles.link}
              style={footerTextColor ? { color: footerTextColor } : undefined}
              href={urlWithUtms}
              key={`anchor_${key}`}
            >
              {t<string>(item.tag)}
            </a>
          );
          return item?.isExternal ? (
            anchor
          ) : (
            <Link key={`${key}_${item.tag}`} href={urlWithUtms} passHref>
              {anchor}
            </Link>
          );
        })}
      </div>
      <Description description={footerText} />
      <div className={styles.powered}>
        <div className={styles.powered_text}>{t<string>("registration:landing.poweredBy")}</div>
        <Image className={styles.powered_image} alt="Magneto" src={PoweredByImage} height={32} loading="lazy" />
      </div>
      <div className={`${styles["footerLanding_marginText"]} ${styles.links}`}>
        {termsAndConditions.map(({ text, url }, key) => (
          <a
            className={styles.link}
            style={footerTextColor ? { color: footerTextColor } : undefined}
            key={`${key}-${text}`}
            href={fillUrlUtms(url, query)}
          >
            {t<string>(text)}
          </a>
        ))}
      </div>
    </footer>
  );
};

export default withTranslation("registration")(FooterLanding);
