import React, { createContext, useCallback, useState, useEffect, ReactNode } from "react";
import { TVideosCarouselTestimonies, TSettingsSlide, TVideoOptions, TVideosCarouselVacants } from "#types/landing";
import { howManySlidesToShow } from "@utils/landing";

const videoOptions: TVideoOptions = {
  autoplay: true,
  controls: true,
  fluid: true,
  preload: "none",
  aspectRatio: "16:9",
  poster: undefined
};

type ContextProps =
  | (TVideosCarouselTestimonies & {
      settingsSlide: TSettingsSlide;
      videoOptions: TVideoOptions;
    })
  | (TVideosCarouselVacants & {
      settingsSlide: TSettingsSlide;
      videoOptions: TVideoOptions;
    });

export const CarouselVideosContext = createContext<ContextProps>({} as ContextProps);

type Props = {
  videosCarouselConfig: TVideosCarouselTestimonies | TVideosCarouselVacants;
  children: ReactNode;
  className?: string;
  classNameChildren?: string;
};

const CarouselVideos: React.FC<Props> = ({ videosCarouselConfig, children, className }) => {
  const { videos } = videosCarouselConfig;
  const [slidesToShow, setSlidesToShow] = useState(howManySlidesToShow(videos.length));

  const handleResize = useCallback(() => {
    setSlidesToShow(howManySlidesToShow(videos.length));
  }, [videos.length]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [videos.length, handleResize]);

  const settingsSlide: TSettingsSlide = {
    arrows: false,
    dots: Math.floor(slidesToShow) > 1,
    slidesToShow,
    slidesToScroll: Math.floor(slidesToShow),
    className: `${className}_content`,
    effect: "scrollx"
  };

  return (
    <CarouselVideosContext.Provider
      value={{
        settingsSlide,
        videoOptions,
        ...videosCarouselConfig
      }}
    >
      <div className={className}>{children}</div>
    </CarouselVideosContext.Provider>
  );
};

export default CarouselVideos;
