export type TSocialMedia = {
  tag: string;
  props: {
    icon: string;
    background: string;
  };
};

export const SOCIAL_MEDIAL: TSocialMedia[] = [
  {
    tag: "facebookUrl",
    props: {
      icon: "lab la-facebook-f",
      background: "#3b5998"
    }
  },
  {
    tag: "instagramUrl",
    props: {
      icon: "lab la-instagram",
      background: "#E1306C"
    }
  },
  {
    tag: "linkedinUrl",
    props: {
      icon: "lab la-linkedin-in",
      background: "#0e76a8"
    }
  },
  {
    tag: "twitterUrl",
    props: {
      icon: "lab la-x",
      background: "#00acee"
    }
  },
  {
    tag: "youtubeUrl",
    props: {
      icon: "lab la-youtube",
      background: "#c4302b"
    }
  },

  {
    tag: "webSite",
    props: {
      icon: "las la-globe",
      background: ""
    }
  }
];
