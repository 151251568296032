export const TERMS_AND_CONDITIONS_SCHEMA = {
  termsAndConditions: [
    {
      text: "registrationType.termsAndConditions.personalDataProcessing",
      url: "https://www.magneto365.com/es/politicas/politica-de-tratamiento-de-datos-personales-magneto-global-s-a-s"
    },
    {
      text: "registrationType.termsAndConditions.personalInformationPolicy",
      url: "https://www.magneto365.com/home/politicas/consentimiento-de-usuarios/"
    },
    {
      text: "registrationType.termsAndConditions.termsAndConditions",
      url: "https://www.magneto365.com/home/politicas/terminos-condiciones-uso-plataforma-magneto/"
    }
  ],
  templateString: "registrationType.termsAndConditions.templateString"
};
