import { I18n, TFunction } from "next-i18next";
import { Fragment, useMemo } from "react";
// import { fullBasePath } from "@constants/environment.constant";
import { NextRouter } from "next/router";
import { TDescription } from "#types/metaTags";
import { canonicalUrls } from "@utils/common/canonicalUrls";
import { EPageType } from "@enums/landing.enum";

type ComposedProps = {
  t: TFunction;
  i18n: I18n;
  nextRouter: NextRouter;
  pageType: EPageType;
  interpolationVars?: Pick<TDescription, "company"> | { month?: string; year?: number; country?: string };
  jobConfidential?: boolean;
};

const withOgTitleList = [EPageType.mainPage, EPageType.jobDetailPage];

const MetaTags: React.FC<ComposedProps> = ({
  t,
  nextRouter,
  pageType,
  interpolationVars,
  jobConfidential
}: ComposedProps) => {
  const withOgTitle = useMemo(() => withOgTitleList.includes(pageType), [pageType]);

  return (
    <Fragment>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

      <meta name="description" content={t<string>(`translation:${pageType}.description`, interpolationVars)} />

      <link rel="canonical" href={canonicalUrls(pageType, t, nextRouter)} />

      <meta
        property="og:title"
        content={t<string>(
          `translation:${
            pageType === EPageType.jobDetailPage && !!jobConfidential
              ? `${pageType}.ogTitleConfidential`
              : `${pageType}.${withOgTitle ? "ogTitle" : "mainTitle"}`
          }`,
          interpolationVars
        )}
      />

      <meta property="fb:app_id" content="1512397642110482" />

      <meta property="og:description" content={t<string>(`translation:${pageType}.description`, interpolationVars)} />
      <meta property="og:url" content={canonicalUrls(pageType, t, nextRouter)}></meta>

      <meta property="og:image:alt" content={t<string>(`translation:${pageType}.description`, interpolationVars)} />
      <meta property="og:locale" content={nextRouter.locale} />
      <meta property="og:site_name" content="Magneto Empleos" />
      <meta property="og:type" content="website" />

      <meta
        name="twitter:title"
        content={t<string>(
          `translation:${
            pageType === EPageType.jobDetailPage && !!jobConfidential
              ? `${pageType}.ogTitleConfidential`
              : `${pageType}.${withOgTitle ? "ogTitle" : "mainTitle"}`
          }`,
          interpolationVars
        )}
      />
      <meta name="twitter:description" content={t<string>(`translation:${pageType}.description`, interpolationVars)} />
    </Fragment>
  );
};

export default MetaTags;
