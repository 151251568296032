import { EPageType } from "@enums/landing.enum";
import { fullBasePath } from "@constants/environment.constant";
import { TFunction } from "next-i18next";
import { NextRouter } from "next/router";

export const canonicalUrls = (pageType: string, t: TFunction, nextRouter: NextRouter) => {
  if (pageType === EPageType.jobDetailPage) return fullBasePath + t<string>("routes:jobsView", nextRouter.query);

  if (pageType === EPageType.mainPage) return fullBasePath + t<string>("routes:jobs");

  return fullBasePath + nextRouter.asPath;
};
