import React, { useContext } from "react";
import { CarouselVideosContext } from "../component";
import { fixCssProps } from "@utilsstyle";

type Props = {
  className: string;
  style: {
    colorTitle?: string;
    colorSubtitle?: string;
    fontFamilyTitle?: string;
    fontFamilySubTitle?: string;
  };
};

export const CarouselVideosTitle: React.FC<Props> = ({ className, style }) => {
  const { title, subtitle } = useContext(CarouselVideosContext);
  const { colorTitle, colorSubtitle, fontFamilyTitle, fontFamilySubTitle } = style;

  return (
    <React.Fragment>
      <h1 style={fixCssProps({ color: colorTitle, fontFamily: fontFamilyTitle })} className={className}>
        {title}
      </h1>
      {subtitle && (
        <h3 style={fixCssProps({ color: colorSubtitle, fontFamily: fontFamilySubTitle })} className={className}>
          {subtitle}
        </h3>
      )}
    </React.Fragment>
  );
};
