import { tail } from "ramda";
import { fullBasePath } from "@constantsenvironment.constant";
import { NextRouter } from "next/router";

const breadcrumbSchema = (routes: NextRouter, selectedJobTitle?: string) => {
  const { query, asPath } = routes;
  const { origin, pathname } = new URL(fullBasePath + asPath);
  const pathName = tail(pathname.split("/"));
  const urlToBreadcrumb = [origin, ...pathName];
  const hasJobSlug = Boolean(query.jobSlug);

  let url = "";
  const listItemName = (index: number, path: string) => {
    if (index === 0) return "Inicio";
    if (hasJobSlug && index === urlToBreadcrumb.length - 1) {
      return selectedJobTitle;
    }
    if (index === urlToBreadcrumb.length - 1) {
      return path.replaceAll("-", " ");
    }

    return path;
  };
  const itemListElement = urlToBreadcrumb.map((path, index) => {
    url += path + "/";
    return {
      "@type": "ListItem",
      position: index + 1,
      name: listItemName(index, path),
      ...((hasJobSlug || index !== urlToBreadcrumb.length - 1) && { item: url.replace(/\/$/, "") })
    };
  });

  return {
    __html: JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: itemListElement
    })
  };
};

export default breadcrumbSchema;
