import { fullBasePath, signUpUserHost } from "@constants/environment.constant";

export type TPagesLinks = {
  tag: string;
  routePath: string;
  baseUrl: string;
  isExternal: boolean;
  openNewTab?: boolean;
};

export const PAGES_COMPANY: TPagesLinks[] = [
  {
    tag: "registration:landing.companyPages.company",
    routePath: `routes:landing`,
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "registration:landing.companyPages.jobOffers",
    routePath: `routes:jobsByCompany`,
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "registration:landing.companyPages.registerResume",
    routePath: `routes:signUp`,
    baseUrl: `${signUpUserHost}`,
    isExternal: true
  },
  {
    tag: "registration:landing.companyPages.officialSite",
    routePath: `routes:externalSiteByCompany`,
    baseUrl: ``,
    isExternal: true
  }
];
