import { CSSProperties } from "react";

export const fixCssProps = (input?: CSSProperties): CSSProperties => {
  if (!input) return {};
  const output: Record<string, number | string> = {};
  for (const [prop, value] of Object.entries(input)) {
    if (!value) continue;
    output[prop] = value;
  }
  return output;
};
