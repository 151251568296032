import React, { Fragment, useContext, useMemo, useState } from "react";
import { I18n, TFunction, withTranslation } from "next-i18next";
import { isEmpty } from "ramda";
import CardDescription from "./cardDescription/component";
import CarouselVideos from "./carouselVideos/component";
import CompanyHeader from "./companyHeader/component";
import CompanyButton from "./companyButton/component";
import FloatLog from "./floatLog/component";
import FooterLanding from "./footer/component";
import { CarouselVideosTitle } from "./carouselVideos/children/carouselVideosTitle.component";
import CarouselVideosSlider from "./carouselVideos/children/carouselVideosSlider.wrap";
import { AuthContext, LandingContext } from "@context";
import { assetsType } from "@constants/landing.constant";
import { testimoniesSchema, vacantSchema } from "@schemas/landing";
import { TLanding, TVideosCarouselTestimonies, TVideosCarouselVacants } from "#types/landing";
import styles from "./content.module.scss";

type Props = {
  t: TFunction;
  i18n: I18n;
};

const Landing: React.FC<Props> = ({ t }) => {
  const [showFloatLog, setShowFloatLog] = useState(true);
  const { landingPage } = useContext(LandingContext);
  const { isAuthenticated } = useContext(AuthContext);
  // const nextRouter = useRouter();
  const {
    footer,
    companySocialMedia,
    section1,
    section2,
    testimoniesVideoCarousel,
    vacanciesModule,
    generalPageSettings,
    registerPage,
    mainCompanySlug,
    curriculumVitaeRecord,
    generalMenuSettings
  } = landingPage as TLanding;

  const { colorLogoUrl } = generalMenuSettings;

  const videosCarouselTestimonies = testimoniesSchema({
    ...testimoniesVideoCarousel,
    titleFontUrl: generalPageSettings.titleFontUrl,
    subtitleFontUrl: generalPageSettings.subtitleFontUrl
  });

  const videosCarouselVacants = vacantSchema({
    ...vacanciesModule,
    titleFontUrl: generalPageSettings.titleFontUrl,
    subtitleFontUrl: generalPageSettings.subtitleFontUrl
  });

  const registerInfo = {
    ...registerPage,
    ...curriculumVitaeRecord,
    mainCompanySlug,
    colorLogoUrl: colorLogoUrl ? colorLogoUrl : ""
  };

  const displayFonts = useMemo(() => {
    if (!generalPageSettings.titleFontUrl) return <Fragment />;
    const fontVarName = generalPageSettings.titleFontUrl.replace(/\s/gm, "+");
    return (
      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=${fontVarName}&display=swap");
      `}</style>
    );
  }, [generalPageSettings]);

  return (
    <Fragment>
      {displayFonts}
      <CompanyHeader headerData={landingPage as TLanding} />
      {section1.section1Enabled && (
        <CardDescription
          styles={{ background: section1.section1BgColor }}
          description={section1.section1Html}
          url={section1.section1ImgUrl}
        >
          <CompanyButton
            background={section1.section1BtnColorBg}
            color={section1.section1BtnColorText}
            link={section1.section1BtnLink}
            text={section1.section1BtnText}
          />
        </CardDescription>
      )}
      {!isEmpty(testimoniesVideoCarousel.videos) && !testimoniesVideoCarousel.videos.find((item) => !item.videoUrl) && (
        <CarouselVideos
          videosCarouselConfig={videosCarouselTestimonies as TVideosCarouselTestimonies}
          className={styles["carousel"]}
        >
          <CarouselVideosTitle
            className={styles["carousel_title"]}
            style={{
              colorTitle: testimoniesVideoCarousel.testimonialsColorTitle,
              fontFamilyTitle: generalPageSettings.titleFontUrl
            }}
          />
          <CarouselVideosSlider className={styles["carousel_video"]} />
        </CarouselVideos>
      )}

      {section2.section2Enabled && (
        <CardDescription
          styles={{ background: section2.section2BgColor }}
          description={section2.section2Html}
          url={section2.section2ImgUrl}
          type={assetsType.image}
        >
          <CompanyButton
            background={section2.section2BtnColorBg}
            color={section2.section2BtnColorText}
            link={section2.section2BtnLink}
            text={section2.section2BtnText}
          />
        </CardDescription>
      )}

      {vacanciesModule.vacantsEnabled && !isEmpty(vacanciesModule.videos) && (
        <CarouselVideos
          videosCarouselConfig={videosCarouselVacants as TVideosCarouselVacants}
          className={styles.carousel}
        >
          <CarouselVideosTitle
            className={styles["carousel_title"]}
            style={{
              colorTitle: vacanciesModule.vacantsTitleColor,
              colorSubtitle: vacanciesModule.vacantsSubtitleColor,
              fontFamilyTitle: generalPageSettings.titleFontUrl,
              fontFamilySubTitle: generalPageSettings.subtitleFontUrl
            }}
          />
          <CarouselVideosSlider className={styles["carousel_video"]} />
        </CarouselVideos>
      )}
      {!isAuthenticated && showFloatLog && (
        <FloatLog t={t} registerInfo={registerInfo} setShowFloatLog={setShowFloatLog} />
      )}
      {!isEmpty(footer) && (
        <FooterLanding data={footer} socialMedia={companySocialMedia} isBrand={landingPage?.mainBrandPage} />
      )}
    </Fragment>
  );
};

export default withTranslation(["registration", "routes"])(Landing);
