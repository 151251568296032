import { NextRouter } from "next/router";
import { TFunction } from "next-i18next";
import { isEmpty } from "ramda";
import { TestimoniesVideoCarousel, VacanciesModule } from "#types/landing";
import { ECarouselType, EQueryAction, EQueryParams } from "@enums";
import { fullBasePath } from "@constants/environment.constant";
import { fillUrlUtms } from "@utils/url";

type TestimoniesProps = TestimoniesVideoCarousel & {
  titleFontUrl: string;
  subtitleFontUrl: string;
};

type VacantProps = VacanciesModule & {
  titleFontUrl: string;
  subtitleFontUrl: string;
};

export const testimoniesSchema = (config: TestimoniesProps) => {
  return (
    !isEmpty(config) && {
      ...config,
      title: config.testimonialsTitle,
      videos: config.videos?.map((video) => ({
        url: video.videoUrl?.url,
        poster: video.videoUrl?.smallThumbnailUrl,
        props: {
          type: ECarouselType.testimonies,
          title: video.title,
          titleStyle: {
            color: config.colorTestimonyTitle,
            fontFamily: config.titleFontUrl
          },
          subtitle: video.subtitle,
          subtitleStyle: {
            color: config.colorTestimonySubtitle,
            fontFamily: config.subtitleFontUrl
          }
        }
      }))
    }
  );
};

export const vacantSchema = (config: VacantProps) => {
  return (
    !isEmpty(config) && {
      ...config,
      title: config.vacantsTitleText,
      subtitle: config.vacantsSubtitleText,
      videos:
        config.videos &&
        config.videos.map((video) => ({
          jobSlug: video.slug,
          url: video.videoUrl.url,
          poster: video.videoUrl.smallThumbnailUrl,
          props: {
            type: ECarouselType.vacant,
            title: video.title,
            titleStyle: {
              color: config.vacantsTitleColor,
              fontFamily: config.titleFontUrl
            },
            subtitle: video.subtitle,
            subtitleStyle: {
              color: config.vacantsSubtitleColor,
              fontFamily: config.subtitleFontUrl
            },
            btnStyle: { background: config.vacantsColorBtns },
            handleApplyOffer: (router: NextRouter, t: TFunction) => {
              const href = `${fullBasePath}${t<string>("routes:jobsByCompany", {
                companySlug: router.query.companySlug
              })}/${video.slug}?${EQueryParams.action}=${EQueryAction.apply}`;
              const urlWithUtms = fillUrlUtms(href, router.query);
              router.push(urlWithUtms);
            },
            handleSeeMore: (router: NextRouter, t: TFunction) => {
              const href = `${fullBasePath}${t<string>("routes:jobsByCompany", {
                companySlug: router.query.companySlug
              })}/${video.slug}`;
              const urlWithUtms = fillUrlUtms(href, router.query);
              router.push(urlWithUtms);
            }
          }
        }))
    }
  );
};
