import { FC, Fragment, useMemo } from "react";
import { useRouter } from "next/router";
import { fillUrlUtms } from "@utils/url";
import styles from "./button.module.scss";

type Props = {
  text: string;
  link: string;
  color: string;
  background: string;
};
const CompanyButton: FC<Props> = ({ text, link, color, background }) => {
  const { query } = useRouter();

  const urlWithUtms = useMemo(() => (link && fillUrlUtms(link, query)) || "#", [link, query]);

  return (
    <Fragment>
      {text && (
        <a style={{ color, background }} href={urlWithUtms} className={styles["landingButton"]}>
          {text}
        </a>
      )}
    </Fragment>
  );
};

export default CompanyButton;
