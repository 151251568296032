import React, { CSSProperties, ReactNode } from "react";
import Image from "next/image";
import { VideoJsPlayerOptions, VideoJsPlayerPluginOptions } from "video.js";
import Description from "./description.wrap";
import { fixCssProps } from "@utils/style";
import { ELazyVideo } from "@enums/lazyVideo.enum";
import stylesModule from "./cardDescription.module.scss";
import LazyVideoComponent from "@componentscommon/customVideo/lazyVideo";

type Props = {
  videoConfig?: VideoJsPlayerOptions | VideoJsPlayerPluginOptions;
  description?: string;
  type?: string;
  url?: string;
  styles?: CSSProperties;
  children?: ReactNode;
  title?: string;
};

const CardDescription: React.FC<Props> = ({ title, description, type, videoConfig, url, styles, children }) => {
  return (
    <div className={stylesModule["companyHeader_description"]} style={fixCssProps(styles)} id="our-company">
      {type === "video" ? (
        <div className={stylesModule["companyHeader_description-video"]}>
          {videoConfig && <LazyVideoComponent vjsOptions={videoConfig} className={ELazyVideo.landingDescription} />}
        </div>
      ) : (
        <div className={stylesModule["companyHeader_description-img"]}>
          <Image src={url as string} width={700} height={450} alt="logo" objectFit="contain" loading="lazy" />
        </div>
      )}
      <div className={stylesModule["companyHeader_description-text"]}>
        {title && <h1>{title}</h1>}
        {description && <Description description={description} />}
        {children && <div className={stylesModule["companyHeader_companyButton"]}>{children}</div>}
      </div>
    </div>
  );
};

export default CardDescription;
