import React from "react";
import Image from "next/image";
import { TFunction } from "next-i18next";
import { Button } from "antd";
import { CurriculumVitaeRecord, RegisterPage } from "#types/landing";
import { CreateAccount } from "@components/signUp/createAccountButton/component";
import styles from "./floatLog.module.scss";
import classNames from "classnames";

type Props = {
  t: TFunction;
  registerInfo: RegisterPage & CurriculumVitaeRecord & { colorLogoUrl: string } & { mainCompanySlug: string };
  setShowFloatLog: React.Dispatch<React.SetStateAction<boolean>>;
};

const FloatLog: React.FC<Props> = ({ t, registerInfo, setShowFloatLog }) => {
  const { colorLogoUrl, registerTitle, registerBackgroundColor } = registerInfo;

  return (
    <div
      className={styles["floatLog"]}
      style={registerBackgroundColor ? { background: registerBackgroundColor } : undefined}
    >
      <Button className={styles["floatLog_btnClose"]} onClick={() => setShowFloatLog(false)}>
        <i className="las la-times" />
      </Button>
      <div className={classNames(styles["floatLog_sections"], styles["floatLog_img"])}>
        {colorLogoUrl && (
          <Image width={105} height={45} alt="Logo" objectFit="contain" src={colorLogoUrl} loading="lazy" />
        )}
      </div>
      <div className={classNames(styles["floatLog_sections"], styles["floatLog_text"])}>
        <label>{registerTitle ? registerTitle : t<string>("landing.register.title")}</label>
      </div>
      <div className={classNames(styles["floatLog_sections"], styles["floatLog_button"])}>
        <CreateAccount
          t={t}
          name={t<string>("landing.register.button")}
          propStyle={styles["floatLog_button-btnRegister"]}
          withIcon={false}
        />
      </div>
    </div>
  );
};

export default FloatLog;
